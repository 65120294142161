// A map of breakpoints.
$breakpoints: (
	mobile: 480px,
	tablet: 810px,
	laptop: 1024px,
	desktop: 1280px,
	desktop-lg: 1800px,
);

@mixin breakpoint($breakpoint, $direction: max) {
	@if map-has-key($breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		@if $direction == max {
			@media only screen and (max-width: ($breakpoint-value)) {
				@content;
			}
		} @else if $direction == min {
			@media only screen and (min-width: $breakpoint-value + 1) {
				@content;
			}
		} @else {
			@media only screen and ($direction: $breakpoint-value) {
				@content;
			}
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		@if $direction == max {
			@media only screen and (max-width: $breakpoint) {
				@content;
			}
		} @else if $direction == min {
			@media only screen and (min-width: $breakpoint) {
				@content;
			}
		} @else {
			@media only screen and ($direction: $breakpoint) {
				@content;
			}
		}
	}
}
