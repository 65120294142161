@use "sass:color";

/* Color Variables (from Style Guide) */
$white: #ffffff;
$off-white: #f3f3f3;
$gray-200: #e5e7eb;
$medium-grey: #BDBDBD;
$grey: #9e9e9e;
$dark-grey: #616161;
$charcoal: #4a4a4a;

$ss-blue: #004E80;
$ss-light-blue: #599ee1;
$ss-green: #22E9B6;
$ss-teal: #26a69a;
$ss-red: #EE6D72;
$ss-orange: #F57C05;

$ss-padding: 16px;

$cell-phone-width: 500px;
$max-content-width: 1360px;
