@use '@angular/material' as mat;
@use 'variables' as *;
@use 'breakpoints' as *;
@use "sass:selector";

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
	.icon-sm {
		height: 1.5rem;
		width: 1.5rem;
		font-size: 1.5rem;
	}

	.icon-md {
		height: 2rem;
		width: 2rem;
		font-size: 2rem;
	}

	.icon-lg {
		height: 2.5rem;
		width: 2.5rem;
		font-size: 2.5rem;
	}
}


@include mat.core();

$ss-blue-set: (
	50 : #f2f6f9,
	100 : #e6edf2,
	200 : #bfd3df,
	300 : #99b8cc,
	400 : #4d83a6,
	500 : #004E80,
	600 : #004673,
	700 : #003b60,
	800 : #002f4d,
	900 : #00263f,
	A100 : #83afff,
	A200 : #508fff,
	A400 : #1d6eff,
	A700 : #035eff,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #000000,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #000000,
		A200 : #000000,
		A400 : #ffffff,
		A700 : #ffffff,
	)
);

$ss-green-set: (
	50: #f4fefb,
	100: #e9fdf8,
	200: #c8faed,
	300: #a7f6e2,
	400: #64f0cc,
	500: #22e9b6,
	600: #1fd2a4,
	700: #1aaf89,
	800: #148c6d,
	900: #117259,
	A100: #70ffe9,
	A200: #00b687,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #000000,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000
	),
);

$ss-red-set: (
	50: #fef2f2,
	100: #fee2e2,
	200: #fecaca,
	300: #fca5a5,
	400: #f87171,
	500: #ef4444,
	600: #dc2626,
	700: #b91c1c,
	800: #991b1b,
	900: #7f1d1d,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff
	),
);

$nta-pink-set: (
	50: #FDEAF2,
	100: #F9CADF,
	200: #F9CADF,
	300: #F184B3,
	400: #EE69A3,
	500: #EB4F93,
	600: #E9488B,
	700: #E53F80,
	800: #E23676,
	900: #DD2664,
	A100: #FFFFFF,
	A200: #FFE5ED,
	A300: #FFB3CA,
	A400: #FF99B9,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A300: #000000,
		A400: #000000
	),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$simpliscreen-primary: mat.m2-define-palette($ss-green-set,500,A100,A200);
$simpliscreen-accent: mat.m2-define-palette($ss-blue-set,500,A100,A200);
$nta-primary: mat.m2-define-palette($nta-pink-set,500,A100,A200);

// The warn palette is optional (defaults to red).
$simpliscreen-warn: mat.m2-define-palette($ss-red-set,400);

$simpliscreen-typography: mat.m2-define-typography-config(
	$font-family: 'Inter',
	$headline-5: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$simpliscreen-theme: mat.m2-define-light-theme((
	color: (
		primary: $simpliscreen-primary,
		accent: $simpliscreen-accent,
		warn: $simpliscreen-warn,
	),
	typography: $simpliscreen-typography,
));

$nta-theme: mat.m2-define-light-theme((
	color: (
		primary: $nta-primary,
		accent: $simpliscreen-primary,
		warn: $simpliscreen-warn,
	),
	typography: $simpliscreen-typography,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($simpliscreen-theme);

@include mat.autocomplete-theme($simpliscreen-theme);
@include mat.card-theme($simpliscreen-theme);
@include mat.progress-bar-theme($simpliscreen-theme);
@include mat.progress-spinner-theme($simpliscreen-theme);
@include mat.datepicker-theme($simpliscreen-theme);
@include mat.tooltip-theme($simpliscreen-theme);
@include mat.form-field-theme($nta-theme);
@include mat.input-theme($simpliscreen-theme);
@include mat.select-theme($simpliscreen-theme);
@include mat.dialog-theme($simpliscreen-theme);
@include mat.slide-toggle-theme($nta-theme);
@include mat.menu-theme($simpliscreen-theme);
@include mat.button-theme($simpliscreen-theme);
@include mat.icon-button-theme($simpliscreen-theme);
@include mat.expansion-theme($simpliscreen-theme);
@include mat.sidenav-theme($simpliscreen-theme);
@include mat.toolbar-theme($simpliscreen-theme);
@include mat.radio-theme($simpliscreen-theme);
@include mat.checkbox-theme($simpliscreen-theme);
@include mat.badge-theme($nta-theme);
@include mat.chips-theme($simpliscreen-theme);
@include mat.table-theme($simpliscreen-theme);
@include mat.icon-theme($simpliscreen-theme);
@include mat.list-theme($simpliscreen-theme);
@include mat.paginator-theme($simpliscreen-theme);
@include mat.slider-theme($simpliscreen-theme);
@include mat.stepper-theme($simpliscreen-theme);
@include mat.sort-theme($simpliscreen-theme);

html,
body {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
}

@media not print {
	html, body {
		overflow: auto;
	}
}
body {
	margin: 0;
	font-family: Inter, sans-serif;
	box-sizing: border-box;
}

html {
	display: flex;
	position: fixed;
	scroll-behavior: smooth;
}

*,
*:before,
*:after {
	box-sizing: inherit;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
}

@media print {
	.no-print{
		display: none !important;
	}

	html{
		position: static;
	}

	.mdc-tooltip__surface.mdc-tooltip__surface-animation {
		display: none;
	}
}

mat-dialog-content{
	.dialog-title {
		@apply text-ss-blue text-4xl font-normal;
	}
}

mat-dialog-actions{
	@apply px-5 py-6 #{!important};
}

[mat-button]{
	@apply px-3;
}

h4{
	@apply mb-4
}
.mdc-snackbar__surface{
	--mdc-snackbar-container-color: #fff;
}

.no-padding-dialog {
	@include breakpoint(mobile){
		position: fixed !important;
		top: 100px;
		width: 502px !important;
		max-width: 100vw !important;
	}
}

.mat-icon.fa {
	font-size: 20px;
}

h2{
	@apply text-ss-blue text-4xl font-normal;
}

h4{
	font-size: 24px !important;
	line-height: 24px !important;
	color: $ss-blue;
}

a{
	@apply text-ss-green-800;

	text-decoration: none;

	&:hover{
		text-decoration: underline;
	}
}

button{
	@apply whitespace-nowrap;

	.mdc-button__ripple {
		@apply transition-default;
	}

	.mdc-button__label {
		@apply tracking-normal;
	}
	a{
		color: inherit;
		&:hover{
			text-decoration: none;
		}
	}
	&[mat-raised-button], &[mat-flat-button], &[mat-fab], &[mat-mini-fab]{

		&[color="warn"], &[color="accent"] {
			.mdc-button__label{
				@apply text-white;
			}
		}
	}
}

.transition-default {
	@apply transition-all duration-300 ease-in-out;
}

.mat-mdc-snack-bar-container{
	@supports (bottom: env(safe-area-inset-bottom)) {
		margin-top: calc(env(safe-area-inset-top) + 24px) !important;
	}
}

mat-toolbar[color="primary"] {
	@apply bg-black;
}

mat-header-row {
	min-height: 3rem !important;
}

.mat-mdc-menu-panel {
	@apply shadow-md border-solid border  border-gray-300 mt-0.5 #{!important};

	.mat-mdc-menu-content {
		@apply p-0 #{!important};
		.mat-mdc-menu-item:not(:last-of-type) {
			@apply transition-all duration-300 ease-in-out shadow-gray-200 shadow-[0_1px_0] #{!important};
		}
	}
}

.mat-horizontal-content-container {
	@apply px-0 #{!important};
}
.mat-horizontal-stepper-header-container {
	@apply h-auto gap-x-2 pb-4 px-0 #{!important};
}
.mat-step-header-ripple {
	height: auto;
}
.mat-stepper-horizontal-line {
	display: none !important;
}
.mat-horizontal-stepper-header {
	height: auto !important;
}
.mat-step-icon {
	display: none !important;
}
.mat-step-header {
	@apply h-auto p-0 [&:not(:first-of-type)]:before:content-["\203A"] gap-x-2 #{!important};
}
.mat-step-header[ng-reflect-active="true"] .mat-step-label {
	@apply bg-ss-green-700/20 text-ss-green-800 font-medium #{!important};
}
.mat-step-header[ng-reflect-selected="true"] .mat-step-label {
	@apply bg-zinc-200 text-zinc-600 font-medium #{!important};
}
.mat-step-label-active {
	@apply text-sm #{!important};
}

.mat-step-label {
	@apply min-w-0 px-4 py-1 rounded-md  #{!important};
}

.mat-step-header:hover:not([aria-disabled]) {
	@apply bg-inherit #{!important};
}

.mat-mdc-menu-item .mat-icon {
	--mat-menu-item-icon-color: rgb(71 85 105);
	@apply text-slate-600;
}
