.mdc-list-item__primary-text{
	@apply truncate;
	white-space: nowrap !important;
}

.mdc-evolution-chip__action.mat-mdc-chip-action.mdc-evolution-chip__action--primary.mdc-evolution-chip__action--presentational{
	@apply px-3;
}

.mdc-evolution-chip__graphic.mat-mdc-chip-graphic{
	@apply hidden;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary{
	@apply px-3 #{!important};
}

html {
	@apply bg-slate-50;
}

.file-name-menu .mat-mdc-menu-item-text {
	@apply truncate;
	white-space: nowrap !important;
}